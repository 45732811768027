import * as React from 'react';
import { createClasses } from '@kp/react-ui';
import { ApplicationHub } from './application';
import { Greeting } from './greeting';
import { WidgetsContainer } from './widgets';

const classes = createClasses({
  root: {
    padding: '0 100px 0 100px',
  },
});

export const Hub: React.FC = () => {
  return (
    <div className={classes.root}>
      <Greeting />
      <ApplicationHub />
      <WidgetsContainer />
    </div>
  );
};
