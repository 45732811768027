import React, { MouseEventHandler } from 'react';
import {
  Colors,
  IconButton,
  Typography,
  Icons,
  createClasses,
  classNames,
  animations,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { TimeInterval } from '../../api/widgets/common';
import { timeIntervals } from '../../features/widgets/constants';
import * as Apps from '../../constants/Applications';

const classes = createClasses({
  root: {
    border: `1px solid ${Colors.Neutral.borderMedium}`,
    background: Colors.Neutral.background,
    borderRadius: '6px',
    textDecoration: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // Height should be 56px, so we deduct the border height
    minHeight: '55px',
    // Based on the design, the border is included in the spacing
    padding: '12px 24px 11px 24px',
    backgroundColor: Colors.Neutral.background,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    padding: '8px 24px',
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
  },

  empty: {
    borderRadius: '6px',
  },

  full: {
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
  },

  chart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'calc(100% - 68px)',
    padding: '24px 24px 12px 24px',
    overflow: 'auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px solid ${Colors.Neutral.borderMedium}`,
    padding: '12px 16px 16px 24px',
    height: '68px',
    justifyContent: 'space-between',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  name: {
    color: Colors.Neutral.text,
    marginBottom: '4px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timeInterval: {
    color: Colors.Neutral.text,
  },
  info: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  creationDate: {
    color: Colors.Neutral.textWeak,
    marginLeft: '4px',
  },
  separator: {
    color: Colors.Neutral.textWeak,
    marginLeft: '4px',
  },

  favourite: {},
  nameSkeleton: {
    height: '12px',
    width: '120px',
    margin: '4px 0',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, .04) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
  infoSkeleton: {
    height: '12px',
    width: '200px',
    marginTop: '6px',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, .04) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
});

export interface WidgetCardProps extends React.ComponentProps<'div'> {
  loading: boolean;
  name: string;
  widgetId: string;
  buildingId: string;
  timeInterval: TimeInterval;
  createdAt: string;
  onRemoveFavourite: (widgetId: string) => Promise<void>;
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
  className,
  loading,
  name,
  widgetId,
  buildingId,
  timeInterval,
  createdAt,
  onRemoveFavourite,
  children,
}) => {
  const { t } = useTranslation();
  const uri = Apps.BUILDING_INSIGHTS;
  const onButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onRemoveFavourite(widgetId);
  };
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`${uri.uri}buildings/${buildingId}/widgets/${widgetId}`}
      className={classNames(classes.root, className)}
    >
      <div className={classes.chart}>{children}</div>
      <div className={classes.wrapper}>
        <div className={classes.infoWrapper}>
          {loading ? (
            <div className={classes.nameSkeleton} />
          ) : (
            <Typography variant="labelMedium" className={classes.name}>
              {name}
            </Typography>
          )}
          {loading ? (
            <div className={classes.infoSkeleton} />
          ) : (
            <div className={classes.info}>
              <Typography variant="labelSmall" className={classes.timeInterval}>
                {timeInterval && timeIntervals(t)[timeInterval]}
              </Typography>
              <Typography variant="bodySmall" className={classes.separator}>
                ·
              </Typography>
              <Typography variant="bodySmall" className={classes.creationDate}>
                {createdAt}
              </Typography>
            </div>
          )}
        </div>
        {!loading && (
          <div className={classes.favourite}>
            <IconButton
              variant="tertiary"
              data-testid={`widget-button-${widgetId}`}
              icon={Icons.IconStarFilled}
              onClick={onButtonClick}
              active
            />
          </div>
        )}
      </div>
    </a>
  );
};
