/**
 * Response coming from the microservice that is not paginated
 */
export interface EntityResponse<T> {
  data: T;
}

export const groupByToMap = <T, Q>(
  ar: T[],
  predicate: (value: T, index: number, array: T[]) => Q,
) =>
  ar.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());
