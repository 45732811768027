import { ProtectedRoute } from '@kp/react-sdk';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import {
  TimeScaleStackedLinearScale,
  TimeScaleStackedLinearCompactScale,
  TimeScaleBarElement,
  TimeScaleBarController,
} from '@kp/react-ui';
import { PageLayout } from './components/PageLayout';
import { NotFoundHandler } from './contexts/generic-page-context';
import { Hub } from './features/Hub';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  TimeScaleStackedLinearScale,
  TimeScaleStackedLinearCompactScale,
  TimeScaleBarElement,
  TimeScaleBarController,
);

const App: React.FC = () => {
  return (
    <PageLayout>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Hub />} />} />
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </PageLayout>
  );
};

export default App;
