import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router';
import { ErrorNotFound } from '../components/ErrorNotFound';

interface GenericPageContextValue {
  activateNotFound: () => void;
  navigateToHome: () => void;
}

const GenericPageContext = createContext<GenericPageContextValue>({
  activateNotFound: () => {},
  navigateToHome: () => {},
});

export const useGenericPages = (): GenericPageContextValue => {
  const values = useContext(GenericPageContext);
  if (!values.activateNotFound) {
    throw new Error(
      `useGenericPageContext must be used within a GenericPageProvider`,
    );
  }
  return values;
};

interface GenericPageProviderProps {
  children?: React.ReactNode;
}

export const GenericPageProvider: React.FC<GenericPageProviderProps> = ({
  children,
}) => {
  const [isNotFound, setIsNotFound] = useState(false);
  const navigate = useNavigate();
  const activateNotFound = useCallback(() => setIsNotFound(true), []);
  const navigateToHome = useCallback(() => {
    setIsNotFound(false);
    navigate('/');
  }, [navigate]);
  const context = useMemo(
    () => ({ activateNotFound, navigateToHome }),
    [activateNotFound, navigateToHome],
  );

  return (
    <GenericPageContext.Provider value={context}>
      {isNotFound ? <ErrorNotFound onButtonClick={navigateToHome} /> : children}
    </GenericPageContext.Provider>
  );
};

export const NotFoundHandler: React.FC = () => {
  const { activateNotFound } = useGenericPages();

  useEffect(() => activateNotFound(), [activateNotFound]);

  return <></>;
};
