import { AggregationInterval, TimeInterval } from './common';
import { AggregationIntervals, TimeSpans } from '../../__generated__/types';

export const timeIntervalToGraphQL = (
  timeInterval: TimeInterval,
): TimeSpans | null => {
  switch (timeInterval) {
    case TimeInterval.last15Minutes:
      return TimeSpans.Last_15Min;
    case TimeInterval.last30Minutes:
      return TimeSpans.Last_30Min;
    case TimeInterval.lastHour:
      return TimeSpans.Last_1Hour;
    case TimeInterval.last2Hours:
      return TimeSpans.Last_2Hour;
    case TimeInterval.last4Hours:
      return TimeSpans.Last_4Hour;
    case TimeInterval.last12Hours:
      return TimeSpans.Last_12Hour;
    case TimeInterval.lastDay:
      return TimeSpans.Last_24Hour;
    case TimeInterval.last7Days:
      return TimeSpans.Last_7Day;
    case TimeInterval.last30Days:
      return TimeSpans.Last_30Day;
    case TimeInterval.last90Days:
      return TimeSpans.Last_90Day;
    default:
      return null;
  }
};

export const aggregationIntervalToGraphQL = (
  aggregationInterval: AggregationInterval,
): AggregationIntervals => {
  switch (aggregationInterval) {
    case AggregationInterval.none:
      return AggregationIntervals.None;
    case AggregationInterval.ms500:
      return AggregationIntervals.Ms_500;
    case AggregationInterval.s01:
      return AggregationIntervals.S_01;
    case AggregationInterval.s02:
      return AggregationIntervals.S_02;
    case AggregationInterval.s03:
      return AggregationIntervals.S_03;
    case AggregationInterval.s04:
      return AggregationIntervals.S_04;
    case AggregationInterval.s05:
      return AggregationIntervals.S_05;
    case AggregationInterval.s06:
      return AggregationIntervals.S_06;
    case AggregationInterval.s10:
      return AggregationIntervals.S_10;
    case AggregationInterval.s12:
      return AggregationIntervals.S_12;
    case AggregationInterval.s15:
      return AggregationIntervals.S_15;
    case AggregationInterval.s20:
      return AggregationIntervals.S_20;
    case AggregationInterval.s30:
      return AggregationIntervals.S_30;
    case AggregationInterval.m01:
      return AggregationIntervals.M_01;
    case AggregationInterval.m02:
      return AggregationIntervals.M_02;
    case AggregationInterval.m03:
      return AggregationIntervals.M_03;
    case AggregationInterval.m04:
      return AggregationIntervals.M_04;
    case AggregationInterval.m05:
      return AggregationIntervals.M_05;
    case AggregationInterval.m06:
      return AggregationIntervals.M_06;
    case AggregationInterval.m10:
      return AggregationIntervals.M_10;
    case AggregationInterval.m12:
      return AggregationIntervals.M_12;
    case AggregationInterval.m15:
      return AggregationIntervals.M_15;
    case AggregationInterval.m20:
      return AggregationIntervals.M_20;
    case AggregationInterval.m30:
      return AggregationIntervals.M_30;
    case AggregationInterval.h01:
      return AggregationIntervals.H_01;
    case AggregationInterval.h02:
      return AggregationIntervals.H_02;
    case AggregationInterval.h03:
      return AggregationIntervals.H_03;
    case AggregationInterval.h04:
      return AggregationIntervals.H_04;
    case AggregationInterval.h06:
      return AggregationIntervals.H_06;
    case AggregationInterval.h08:
      return AggregationIntervals.H_08;
    case AggregationInterval.h12:
      return AggregationIntervals.H_12;
    case AggregationInterval.d01:
      return AggregationIntervals.D_01;
    case AggregationInterval.d02:
      return AggregationIntervals.D_02;
    case AggregationInterval.d03:
      return AggregationIntervals.D_03;
    case AggregationInterval.d04:
      return AggregationIntervals.D_04;
    case AggregationInterval.d05:
      return AggregationIntervals.D_05;
    case AggregationInterval.d06:
      return AggregationIntervals.D_06;
    case AggregationInterval.d07:
      return AggregationIntervals.D_07;
    default:
      return AggregationIntervals.None;
  }
};
