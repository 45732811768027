import React from 'react';

export const ErrorIcon: React.FC = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0257 11.3085C6.2854 8.94322 7.8974 7.1173 10.2403 6.70119C13.8036 6.06832 20.4319 5.3335 32.0002 5.3335C43.5684 5.3335 50.1967 6.06832 53.76 6.70119C56.103 7.1173 57.715 8.9432 57.9747 11.3085C58.31 14.363 58.6668 19.5891 58.6668 28.0002C58.6668 36.4112 58.31 41.6374 57.9747 44.6918C57.715 47.0571 56.103 48.8831 53.76 49.2991C50.3363 49.9072 44.0832 50.6094 33.3376 50.6635L24.3327 57.8675C22.5867 59.2643 20.0002 58.0212 20.0002 55.7852V50.346C15.4511 50.0735 12.3224 49.669 10.2403 49.2991C7.8974 48.8831 6.2854 47.0571 6.0257 44.6918C5.69034 41.6374 5.3335 36.4112 5.3335 28.0002C5.3335 19.5891 5.69034 14.363 6.0257 11.3085ZM24.0002 24.0002C25.473 24.0002 26.6668 22.8063 26.6668 21.3335C26.6668 19.8607 25.473 18.6669 24.0002 18.6669C22.5274 18.6669 21.3335 19.8607 21.3335 21.3335C21.3335 22.8063 22.5274 24.0002 24.0002 24.0002ZM42.6668 21.3335C42.6668 22.8063 41.473 24.0002 40.0002 24.0002C38.5274 24.0002 37.3335 22.8063 37.3335 21.3335C37.3335 19.8607 38.5274 18.6669 40.0002 18.6669C41.473 18.6669 42.6668 19.8607 42.6668 21.3335ZM25.8577 38.0762C25.4474 39.1018 24.2835 39.6006 23.2579 39.1904C22.2323 38.7801 21.7335 37.6162 22.1438 36.5906C23.4138 33.4154 26.4891 31.3334 29.9089 31.3334H34.0926C37.5123 31.3334 40.5877 33.4154 41.8577 36.5906C42.2679 37.6162 41.7691 38.7801 40.7435 39.1904C39.7179 39.6006 38.5541 39.1018 38.1438 38.0762C37.4811 36.4197 35.8767 35.3334 34.0926 35.3334H29.9089C28.1247 35.3334 26.5203 36.4197 25.8577 38.0762Z"
      fill="#C8CBD0"
    />
  </svg>
);
