import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from '@kp/react-ui';
import { de, enUS } from 'date-fns/locale';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en', 'de'],
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

registerLocale('de', de);
registerLocale('en', enUS);

export default i18n;
