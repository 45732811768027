import urljoin from 'url-join';
import { USER_SERVICE_URI } from '../../utils/env';

/**
 * Available languages
 */
export enum Language {
  en = 'en',
  de = 'de',
}

/**
 * User settings entity
 */
export interface UserSettingsEntity {
  language: Language;
}

/**
 * Create url that points to the user service
 *
 * @param segment  The segment path of the url
 */
export const url = (segment: string): string =>
  urljoin(USER_SERVICE_URI, segment);
