import React from 'react';
import { Typography, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';

const classes = createClasses({
  root: {
    paddingTop: '50px',
  },
});

export const Greeting: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const hours = new Date().getHours();
  let greet;

  if (hours < 12) greet = t('greeting.goodMorning');
  else if (hours > 17) greet = t('greeting.goodEvening');
  else greet = t('greeting.goodAfternoon');

  const fullName = user?.info?.name || '';
  return (
    <div className={classes.root}>
      <Typography
        variant="headlineSmall"
        component="span"
        data-testid="greeting"
      >
        {greet}, {fullName}
      </Typography>
    </div>
  );
};
