import { request } from '../utils/request';
import { Response } from '../../hooks/useRequest';
import { url, WidgetEntity } from './common';
import { EntityResponse } from '../common';

export type Widget = WidgetEntity;

/**
 * Get favourite widgets
 */
export const getFavouriteWidgets = async (): Promise<
  Response<EntityResponse<Widget[]>>
> => {
  return request<EntityResponse<Widget[]>>('GET', url('/widgets/favourite'));
};

/**
 * Delete a widget from favourites
 *
 * @param widgetId       The id of a widget, which should be deleted from favourites
 */
export const removeFromFavourite = async (
  widgetId: string,
): Promise<Response<null>> => {
  return request<null>('DELETE', url(`/widgets/favourite/${widgetId}`));
};
