import { TimeUnit } from 'chart.js';
import { differenceInHours } from 'date-fns';
import { Capability, TimeInterval } from '../../api/widgets/common';
import {
  QueryFiltersMultipleDeviceAndCapabilityInput,
  WidgetCardDataQuery,
} from '../../__generated__/types';

export enum WidgetListType {
  TWO_COLUMNS = 'twoColumns',
  THREE_COLUMNS = 'threeColumns',
}

export interface ChartDataSet {
  deviceCapabilityId: string;
  label?: string;
  unitSymbol?: string;
  color: string;
  deviceName: string;
  records: Array<{ x: string; y: number }>;
}

export type TimeRange = {
  min: Date;
  max: Date;
};

export const widgetCapabilitiesToQueryVariable = (
  capabilities: Capability[] = [],
): QueryFiltersMultipleDeviceAndCapabilityInput[] =>
  capabilities.reduce<QueryFiltersMultipleDeviceAndCapabilityInput[]>(
    (result, capability) => {
      const device = result.find(
        ({ deviceId }) => deviceId === capability.deviceId,
      );

      if (device) {
        device.deviceModelCapabilityIds!.push({
          deviceModelCapabilityId: capability.capabilityId,
        });
        return result;
      }

      return result.concat({
        deviceId: capability.deviceId,
        deviceModelCapabilityIds: [
          {
            deviceModelCapabilityId: capability.capabilityId,
          },
        ],
      });
    },
    [],
  );

export const transformDeviceToChartData = (
  capabilities: Capability[],
  widgetData?: WidgetCardDataQuery,
): Array<ChartDataSet> => {
  const chartData = new Array<ChartDataSet>();

  if (!widgetData) {
    return chartData;
  }

  const { multipleDeviceSensorDataByViews } = widgetData;

  if (!multipleDeviceSensorDataByViews) {
    return chartData;
  }

  const multipleDeviceSensorDataElement = multipleDeviceSensorDataByViews[0];
  const deviceAndCapabilityInfos =
    multipleDeviceSensorDataElement?.deviceAndCapabilityInfos;

  if (!deviceAndCapabilityInfos) {
    return chartData;
  }

  for (let i = 0; i < capabilities.length; i += 1) {
    const info = deviceAndCapabilityInfos.find(
      (deviceAndCapabilityInfo) =>
        deviceAndCapabilityInfo?.deviceId === capabilities[i].deviceId,
    );

    if (info?.deviceId && info?.deviceModelCapability) {
      const { deviceId } = info;
      const capabilityId = info.deviceModelCapability.id;
      const capabilityName = info.deviceModelCapability.capability.name;
      const unitSymbol = info?.deviceModelCapability?.unit?.unitSymbol;
      const telemetryRecords = info?.telemetryRecords;
      const deviceName = info?.device?.name;

      if (telemetryRecords) {
        const color = getCapabilityColor(capabilities, deviceId, capabilityId);

        chartData.push({
          deviceCapabilityId: `${deviceId}-${capabilityId}`,
          label: capabilityName ?? `Data ${i}`,
          unitSymbol: unitSymbol || undefined,
          deviceName: deviceName || '',
          records: [],
          color,
        });

        for (let j = 0; j < telemetryRecords.length; j += 1) {
          const record = telemetryRecords[j];

          if (record && record.utcTimeMeasured && record.valueString) {
            chartData[chartData.length - 1].records.push({
              x: record.utcTimeMeasured,
              y: parseFloat(record.valueString),
            });
          }
        }
      }
    }
  }

  chartData.reverse();
  return chartData;
};

export const timeUnitFromInterval = (
  timeInterval: TimeInterval,
): TimeUnit | null => {
  switch (timeInterval) {
    case TimeInterval.last15Minutes:
    case TimeInterval.last30Minutes:
    case TimeInterval.lastHour:
      return 'minute';
    case TimeInterval.last2Hours:
    case TimeInterval.last4Hours:
    case TimeInterval.last12Hours:
    case TimeInterval.lastDay:
      return 'hour';
    case TimeInterval.last7Days:
    case TimeInterval.last30Days:
    case TimeInterval.last90Days:
      return 'day';
    case TimeInterval.customInterval:
      return null;
    default:
      return 'hour';
  }
};

export const timeUnitFromDateInterval = (
  dateFrom?: Date | string | null,
  dateTo?: Date | string | null,
): TimeUnit => {
  if (!dateFrom || !dateTo) {
    return 'hour';
  }

  const diffHours = differenceInHours(
    dateTo instanceof Date ? dateTo : new Date(dateTo),
    dateFrom instanceof Date ? dateFrom : new Date(dateFrom),
  );

  if (diffHours > 24) {
    return 'day';
  }
  if (diffHours < 2) {
    return 'minute';
  }

  return 'hour';
};

export const getTimeRangeForInterval = (
  timeInterval: TimeInterval,
  max = new Date(),
): TimeRange => {
  const min = new Date(max.getTime());

  switch (timeInterval) {
    case TimeInterval.last15Minutes:
      min.setMinutes(min.getMinutes() - 15);
      break;
    case TimeInterval.last30Minutes:
      min.setMinutes(min.getMinutes() - 30);
      break;
    case TimeInterval.lastHour:
      min.setHours(min.getHours() - 1);
      break;
    case TimeInterval.last2Hours:
      min.setHours(min.getHours() - 2);
      break;
    case TimeInterval.last4Hours:
      min.setHours(min.getHours() - 4);
      break;
    case TimeInterval.last12Hours:
      min.setHours(min.getHours() - 12);
      break;
    case TimeInterval.lastDay:
      min.setHours(min.getHours() - 24);
      break;
    case TimeInterval.last7Days:
      min.setHours(min.getHours() - 24 * 7);
      break;
    case TimeInterval.last30Days:
      min.setHours(min.getHours() - 24 * 30);
      break;
    case TimeInterval.last90Days:
      min.setHours(min.getHours() - 24 * 90);
      break;
    default:
      min.setHours(min.getHours() - 1);
      break;
  }

  return { min, max };
};

const getCapabilityColor = (
  capabilities: Capability[],
  deviceId?: string,
  capabilityId?: string,
): string => {
  return (
    capabilities.find(
      (capability) =>
        capabilityId === capability.capabilityId &&
        deviceId === capability.deviceId,
    )?.color || 'rgb(255,99,132)'
  );
};
