import { useEffect, useRef, useState } from 'react';
import { RequestError } from '../api/utils/RequestError';

interface RequestState<T> {
  response?: T;
  loading: boolean;
  error?: RequestError;
}

export interface Response<T> {
  data?: T;
  error?: RequestError;
}

interface PreparedRequestOptions {
  onCompleted?: () => void;
  onError?: (error: Error) => void;
}

export const usePrepareRequest = <
  T extends (...params: any) => Promise<any>,
  Q = ReturnType<T>,
  R = Q extends Promise<infer F> ? F : Q,
  M = R extends Response<infer Z> ? Z : R,
>(
  requestFn: T,
  options: PreparedRequestOptions = {},
): [
  (...params: Parameters<T>) => Promise<RequestState<M>>,
  RequestState<M>,
] => {
  const [state, setState] = useState<RequestState<M>>({ loading: false });

  // This keeps the state of the component using the hook. In case it is not mounted
  // we don't modify the state
  const mounted = useRef(true);

  const action = (...params: Parameters<T>) => {
    setState({ loading: true });
    return requestFn(...params)
      .then(({ error, data }: Response<M>) => {
        if (error) {
          throw error;
        }
        if (mounted.current) {
          setState({ response: data, loading: false, error });
        }
        if (options.onCompleted) {
          options.onCompleted();
        }
        return { response: data, loading: false, error };
      })
      .catch((error: Error) => {
        if (!mounted.current) {
          return { loading: false, error };
        }
        if (options.onError) {
          options.onError(error);
        }
        setState({ loading: false, error });
        console.warn(error);
        return { loading: false, error };
      });
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  return [action, state];
};
