import React from 'react';
import { createClasses } from '@kp/react-ui';
import { Notification } from '../Notification';
import { useNotifications } from '../../contexts/notifications-context';

const classes = createClasses({
  root: {
    position: 'fixed',
    width: '100%',
    bottom: '20px',
    height: 'auto',
  },
  notification: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '5px',
  },
});

export const Notifications: React.FC = () => {
  const { notifications, dismiss } = useNotifications();

  return (
    <div className={classes.root}>
      {notifications.map((notification) => (
        <Notification
          type={notification.type}
          className={classes.notification}
          key={notification.id}
          onDismiss={
            notification.dismissTimeout
              ? undefined
              : () => dismiss(notification.id)
          }
          dismissTimeout={notification.dismissTimeout}
        >
          {notification.content}
        </Notification>
      ))}
    </div>
  );
};
