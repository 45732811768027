import React, { useCallback, useMemo } from 'react';
import {
  Header as UIHeader,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  Avatar,
  Colors,
  Icons,
  Divider,
  LogoB2B,
  createClasses,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import * as Apps from '../../constants/Applications';
import { ACCOUNT_APP_URI, ADMIN_APP_URI } from '../../utils/env';

const classes = createClasses({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  leftActions: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    webkitFontSmoothing: 'antialiased',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.Neutral.text,
    marginLeft: '16px',
  },
  logo: {
    position: 'absolute',
    left: 'calc(50% - 100px)',
  },
  settings: {
    marginRight: '12px',
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  avatar: {
    marginLeft: '16px',
  },
  open: {
    transform: `rotate(180deg)`,
  },
});

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const { signOut, user } = useAuth();
  const { hasPermission } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const apps = useMemo(
    () =>
      [Apps.BUILDING_INSIGHTS, Apps.DEVELOPER].filter(({ permission }) =>
        permission ? hasPermission(permission) : true,
      ),
    [hasPermission],
  );

  const [firstName, lastName] = (user?.info?.name || '').split(/ /);

  return (
    <UIHeader className={classes.header}>
      <div className={classes.leftActions}>
        <div className={classes.title}>Hub</div>
      </div>
      <LogoB2B data-testid="logo" className={classes.logo} />
      <div className={classes.rightActions}>
        <IconButton
          component="a"
          href={ADMIN_APP_URI}
          target="_blank"
          className={classes.settings}
          data-testid="settings"
          icon={Icons.IconSettings}
        />
        <Popover
          data-testid="popover"
          trigger={IconButton}
          icon={Icons.IconApps}
          triggerActiveProp="active"
        >
          <Menu>
            {apps.map((item) => (
              <MenuItem
                component="a"
                href={item.uri}
                target="_blank"
                key={item.label}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Popover>
        <Avatar
          data-testid="avatar"
          firstName={firstName || ''}
          lastName={lastName || ''}
          email={user?.info?.email || ''}
          className={classes.avatar}
        >
          <Menu>
            <MenuItem component="a" href={ACCOUNT_APP_URI} target="_blank">
              {t('header.myAccount')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOut}>{t('header.signOut')}</MenuItem>
          </Menu>
        </Avatar>
      </div>
    </UIHeader>
  );
};
