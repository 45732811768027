import type { TFunction } from 'i18next';
import { TimeInterval } from '../../api/widgets/common';

export const timeIntervals = (
  t: TFunction,
): { [key in TimeInterval]: string } => ({
  [TimeInterval.customInterval]: t(
    'widgetContainer.widget.timeInterval.customInterval',
  ),
  [TimeInterval.last15Minutes]: t(
    'widgetContainer.widget.timeInterval.last15Minutes',
  ),
  [TimeInterval.last30Minutes]: t(
    'widgetContainer.widget.timeInterval.last30Minutes',
  ),
  [TimeInterval.lastHour]: t('widgetContainer.widget.timeInterval.lastHour'),
  [TimeInterval.last2Hours]: t(
    'widgetContainer.widget.timeInterval.last2Hours',
  ),
  [TimeInterval.last4Hours]: t(
    'widgetContainer.widget.timeInterval.last4Hours',
  ),
  [TimeInterval.last12Hours]: t(
    'widgetContainer.widget.timeInterval.last12Hours',
  ),
  [TimeInterval.lastDay]: t('widgetContainer.widget.timeInterval.lastDay'),
  [TimeInterval.last7Days]: t('widgetContainer.widget.timeInterval.last7Days'),
  [TimeInterval.last30Days]: t(
    'widgetContainer.widget.timeInterval.last30Days',
  ),
  [TimeInterval.last90Days]: t(
    'widgetContainer.widget.timeInterval.last90Days',
  ),
});
