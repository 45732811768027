import React from 'react';

interface DeveloperIconProps {
  className?: string;
}

export const DeveloperIcon: React.FC<DeveloperIconProps> = ({ className }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="64" height="64" rx="6" fill="#7A97CC" />
    <path
      d="M25.4 39.7999L17.6 31.9999L25.4 24.1999C26.18 23.4199 26.18 22.1799 25.4 21.3999C24.62 20.6199 23.38 20.6199 22.6 21.3999L13.42 30.5799C12.64 31.3599 12.64 32.6199 13.42 33.3999L22.6 42.5999C23.38 43.3799 24.62 43.3799 25.4 42.5999C26.18 41.8199 26.18 40.5799 25.4 39.7999ZM38.6 39.7999L46.4 31.9999L38.6 24.1999C37.82 23.4199 37.82 22.1799 38.6 21.3999C39.38 20.6199 40.62 20.6199 41.4 21.3999L50.58 30.5799C51.36 31.3599 51.36 32.6199 50.58 33.3999L41.4 42.5999C40.62 43.3799 39.38 43.3799 38.6 42.5999C37.82 41.8199 37.82 40.5799 38.6 39.7999Z"
      fill="white"
    />
  </svg>
);
