import React from 'react';
import { Colors, Typography, createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    width: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: Colors.Neutral.text,
    ':hover > div:last-child': {
      backgroundColor: Colors.Neutral.backgroundHover,
    },
  },
  title: {
    marginTop: '8px',
    padding: '4px 8px',
    borderRadius: '6px',
  },
});

interface ApplicationItemProps {
  title: string;
  uri: string;
  children: React.ReactNode;
}

export const ApplicationItem: React.FC<ApplicationItemProps> = ({
  title,
  uri,
  children,
}) => {
  return (
    <a target="_blank" rel="noreferrer" href={uri} className={classes.root}>
      {children}
      <Typography
        variant="labelSmall"
        component="div"
        className={classes.title}
      >
        {title}
      </Typography>
    </a>
  );
};
