import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses, NotFoundPage } from '@kp/react-ui';

const classes = createClasses({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
});

interface ErrorNotFoundProps {
  onButtonClick: () => void;
}

export const ErrorNotFound: React.FC<ErrorNotFoundProps> = ({
  onButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <NotFoundPage
      data-testid="404"
      className={classes.root}
      title={t('errors.notFound.title')}
      subtitle={t('errors.notFound.subtitle')}
      buttonText={t('errors.notFound.button')}
      buttonOnClick={onButtonClick}
    />
  );
};
