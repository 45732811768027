import React from 'react';
import {
  Toast,
  Colors,
  Typography,
  classNames,
  createClasses,
} from '@kp/react-ui';
import { ErrorIcon } from '../ErrorIcon';
import { Card } from '../Card';

const classes = createClasses({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: Colors.Neutral.textStrong,
  },
  logo: {
    marginTop: '16px',
    width: '300px',
    height: '36px',
    marginBottom: '64px',
  },
  wrapper: {
    width: '392px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '-48px',
  },
  content: {
    marginTop: '24px',
    width: '100%',
  },
  notifications: {
    position: 'absolute',
    bottom: '190px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  title: {
    color: Colors.Neutral.text,
  },
});

interface ErrorLayoutProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
  error?: Error | null;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  children,
  className,
  title,
  error,
}) => (
  <div className={classNames(classes.root, className)}>
    <div className={classes.wrapper}>
      <Card className={classes.content}>
        <div className={classes.icon}>
          <ErrorIcon />
        </div>
        <Typography variant="headlineSmall" className={classes.title}>
          {title}
        </Typography>
        {children}
      </Card>
    </div>
    {error && (
      <div className={classes.notifications}>
        <Toast variant="danger">{error.message}</Toast>
      </div>
    )}
  </div>
);
