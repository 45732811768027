import React, { useEffect, useMemo, useState } from 'react';
import { createClasses } from '@kp/react-ui';
import {
  AggregationInterval,
  Capability,
  TimeInterval,
  aggregationIntervalToGraphQL,
  timeIntervalToGraphQL,
  ChartType,
} from '../../api/widgets';
import { WidgetCard } from '../../components/WidgetCard';
import { useLocaleDateFns } from '../../hooks/date-fns';
import { WidgetCardChart } from './WidgetCardChart';
import { useWidgetCardDataQuery } from '../../__generated__/types';
import {
  ChartDataSet,
  timeUnitFromInterval,
  transformDeviceToChartData,
  getTimeRangeForInterval,
  widgetCapabilitiesToQueryVariable,
  timeUnitFromDateInterval,
  TimeRange,
} from './common';
import { useNotifications } from '../../contexts/notifications-context';

const classes = createClasses({
  card: {
    flexGrow: 1,
    width: '100%',
  },
});

interface WidgetCardContainerProps {
  id: string;
  buildingId: string;
  name: string;
  timeInterval: TimeInterval;
  dateFrom?: string;
  dateTo?: string;
  aggregationInterval: AggregationInterval;
  collapsed: boolean;
  createdAt: string;
  capabilities?: Capability[];
  onRemoveFavourite: (widgetId: string) => Promise<void>;
  type: ChartType;
}

export const WidgetCardContainer: React.FC<WidgetCardContainerProps> = ({
  id,
  buildingId,
  name,
  timeInterval,
  dateFrom,
  dateTo,
  aggregationInterval,
  collapsed,
  createdAt,
  capabilities,
  onRemoveFavourite,
  type,
}) => {
  const { format } = useLocaleDateFns();

  const { add } = useNotifications();

  const [data, setData] = useState<ChartDataSet[]>([]);

  const selectedCapabilities = useMemo(
    () => (capabilities || []).filter((capability) => capability.selected),
    [capabilities],
  );

  const devicesQueryVariables = widgetCapabilitiesToQueryVariable(
    capabilities?.filter((item) => item.selected),
  );
  const { data: widgetData, loading } = useWidgetCardDataQuery({
    variables: {
      timeSpan: timeIntervalToGraphQL(timeInterval),
      readFrom: dateFrom,
      readUntil: dateTo,
      readUntilNow: dateTo === null,
      devices: devicesQueryVariables,
      intervalSize: aggregationIntervalToGraphQL(aggregationInterval),
    },
    skip: selectedCapabilities.length === 0,
    onError: (err) =>
      add({
        type: 'default',
        id: err.message,
        content: err.message,
      }),
  });

  useEffect(() => {
    if (selectedCapabilities.length > 0 && widgetData) {
      const chartData = transformDeviceToChartData(
        selectedCapabilities,
        widgetData,
      );
      setData(chartData);
    }
  }, [loading, widgetData, selectedCapabilities, collapsed]);

  const timeRange: TimeRange | undefined = useMemo(() => {
    if (timeInterval !== TimeInterval.customInterval) {
      return getTimeRangeForInterval(timeInterval);
    }

    return dateFrom && dateTo
      ? {
          min: new Date(dateFrom),
          max: new Date(dateTo),
        }
      : undefined;
  }, [timeInterval, dateFrom, dateTo]);

  const timeUnit =
    timeUnitFromInterval(timeInterval) ||
    timeUnitFromDateInterval(dateFrom, dateTo);

  return (
    <WidgetCard
      className={classes.card}
      loading={false}
      buildingId={buildingId}
      widgetId={id}
      name={name}
      timeInterval={timeInterval}
      createdAt={format(new Date(createdAt), 'P')}
      onRemoveFavourite={onRemoveFavourite}
    >
      <WidgetCardChart
        loading={loading}
        chartData={data}
        timeUnit={timeUnit}
        collapsed={collapsed}
        timeRange={timeRange}
        type={type}
      />
    </WidgetCard>
  );
};
