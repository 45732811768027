import React from 'react';
import 'chartjs-adapter-date-fns';
import Lottie from 'react-lottie';
import { TimeUnit } from 'chart.js';
import { createClasses } from '@kp/react-ui';
import { ChartType } from '../../api/widgets';
import { Chart } from '../../components/Chart/Chart';
import chartLoadingAnimationData from '../../animations/chart-loading.json';
import { ChartDataSet, TimeRange } from './common';

const classes = createClasses({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

const animationOptions = {
  loop: true,
  autoplay: true,
  renderer: 'svg',
  animationData: chartLoadingAnimationData,
};

interface WidgetCardChartProps {
  loading: boolean;
  chartData: ChartDataSet[];
  timeUnit: TimeUnit;
  collapsed: boolean;
  timeRange?: TimeRange;
  type: ChartType;
}

export const WidgetCardChart: React.FC<WidgetCardChartProps> = ({
  loading,
  chartData,
  timeUnit,
  collapsed,
  timeRange,
  type,
}) => {
  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <Lottie options={animationOptions} height={64} width={64} />
        </div>
      )}
      {!loading && (
        <Chart
          data={chartData}
          timeUnit={timeUnit}
          collapsed={collapsed}
          timeRange={timeRange}
          type={type}
          autoFit={true}
        />
      )}
    </>
  );
};
