import React, { useMemo } from 'react';
import { useAuth } from '@kp/react-sdk';
import { Card, createClasses } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { ApplicationItem } from '../../components/ApplicationItem';
import * as Apps from '../../constants/Applications';

const classes = createClasses({
  root: {
    marginTop: '24px',
  },
  apps: {
    display: 'flex',
    paddingTop: '24px',
    paddingBottom: '10px',
    gap: '16px',
  },
});

export const ApplicationHub: React.FC = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuth();
  const apps = useMemo(
    () =>
      [
        Apps.ADMINISTRATION,
        Apps.BUILDING_INSIGHTS,
        Apps.ACCOUNT,
        Apps.DEVELOPER,
      ].filter(({ permission }) =>
        permission ? hasPermission(permission) : true,
      ),
    [hasPermission],
  );

  return (
    <Card
      className={classes.root}
      title={t('applicationHub.title') as string}
      data-testid="applications"
    >
      <div className={classes.apps}>
        {apps.map(({ label, icon: Icon, uri }) => (
          <ApplicationItem title={label} uri={uri} key={label}>
            <Icon />
          </ApplicationItem>
        ))}
      </div>
    </Card>
  );
};
