import * as React from 'react';
import { useBuildingInfoQuery } from '../../__generated__/types';
import { WidgetGroupLocation } from '../../components/WidgetGroupLocation/WidgetGroupLocation';
import { useNotifications } from '../../contexts/notifications-context';

interface WidgetGroupLocationContainerProps {
  buildingId: string;
}

export const WidgetGroupLocationContainer: React.FC<
  WidgetGroupLocationContainerProps
> = ({ buildingId }) => {
  const { add } = useNotifications();

  const { data: bildingData, loading } = useBuildingInfoQuery({
    variables: { buildingId },
    onError: (err) =>
      add({
        type: 'danger',
        id: err.message,
        content: err.message,
      }),
  });
  return (
    <WidgetGroupLocation
      building={bildingData?.building?.name}
      site={bildingData?.building?.site.name}
      loading={loading}
    />
  );
};
