import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '@kp/react-sdk';
import {
  AppInsightsErrorBoundary,
  initAppInsights,
} from '@kp/react-sdk/app-insights';
import '@kp/react-ui/dist/styles.css';
import 'normalize.css';
import './utils/i18n';
import { injectGlobal } from '@kp/react-ui';
import App from './App';
import {
  AUTH_URI,
  CLIENT_ID,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
} from './utils/env';
import { SettingsProvider } from './contexts/settings-provider';
import { GenericPageProvider } from './contexts/generic-page-context';
import { Loader } from './components/Loader';
import { GraphQLProvider } from './contexts/graphql-context';
import { NotificationsProvider } from './contexts/notifications-context';
import { ErrorFallback } from './components/Errors';

injectGlobal({
  html: {
    boxSizing: 'border-box',
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  ol: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
  initAppInsights('hub-app', {
    connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
    correlationHeaderExcludedDomains: ['id.*'],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Suspense fallback={<Loader />}>
    <AppInsightsErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={console.warn}
    >
      <Router>
        <Auth
          clientId={CLIENT_ID}
          url={AUTH_URI}
          forbiddenUrl="/forbidden"
          LoadingComponent={Loader}
        >
          <React.StrictMode>
            <SettingsProvider>
              <GraphQLProvider>
                <NotificationsProvider>
                  <GenericPageProvider>
                    <App />
                  </GenericPageProvider>
                </NotificationsProvider>
              </GraphQLProvider>
            </SettingsProvider>
          </React.StrictMode>
        </Auth>
      </Router>
    </AppInsightsErrorBoundary>
  </Suspense>,
);
