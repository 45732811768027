import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Colors, createClasses } from '@kp/react-ui';

const classes = createClasses({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  wrapper: {
    textAlign: 'center',
  },
});

export interface LoaderProps {}

export const Loader: React.FC<LoaderProps> = () => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <TailSpin color={Colors.Neutral.iconWeak} height={100} width={100} />
    </div>
  </div>
);
