import React from 'react';

interface AccountIconProps {
  className?: string;
}

export const AccountIcon: React.FC<AccountIconProps> = ({ className }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="64" height="64" rx="6" fill="#3D5E99" />
    <path
      d="M32 20C35.32 20 38 22.68 38 26C38 29.32 35.32 32 32 32C28.68 32 26 29.32 26 26C26 22.68 28.68 20 32 20Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32C12 20.9543 20.9543 12 32 12C43.0457 12 52 20.9543 52 32ZM20.0217 42.6079C20.3878 38.7854 25.2307 35.3999 32 35.3999C38.7694 35.3999 43.6122 38.7854 43.9783 42.6079C46.4807 39.7843 48 36.0696 48 32C48 23.1634 40.8366 16 32 16C23.1634 16 16 23.1634 16 32C16 36.0696 17.5193 39.7843 20.0217 42.6079Z"
      fill="white"
    />
  </svg>
);
