import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_URI } from '../utils/env';

export const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    } as { [name: string]: string },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          devicesByBuildings: {
            keyArgs: ['deviceId'],
            merge: (existing, incoming, { args }) => {
              const skip = args?.skip || 0;
              if (skip === 0) {
                return [...incoming];
              }
              return [...existing, ...incoming];
            },
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

interface GraphQLProviderProps {
  children?: React.ReactNode;
}

export const GraphQLProvider: React.FC<GraphQLProviderProps> = ({
  children,
}) => <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
