import React, { useState } from 'react';
import { Toast, classNames, createClasses } from '@kp/react-ui';
import { useTimeoutWhen } from 'rooks';

const classes = createClasses({
  content: {
    flexGrow: 1,
    fontSize: 14,
    lineHeight: '17px',
    fontWeight: 400,
  },
});

export interface NotificationProps {
  /** Pass a CSS class name. */
  className?: string;
  /** Set the type of the notification. */
  type?: 'danger' | 'default';
  /** The content of the notification. */
  children: React.ReactNode;
  /** Called when the notification is dismissed. */
  onDismiss?: () => void;
  /** Automatically closes notification after given interval */
  dismissTimeout?: number;
}

export const Notification: React.FC<NotificationProps> = ({
  className,
  children,
  type,
  onDismiss,
  dismissTimeout,
}) => {
  const [show, setShow] = useState(true);

  useTimeoutWhen(() => setShow(false), dismissTimeout, !!dismissTimeout);

  return (
    <div className={className}>
      {show && (
        <Toast onDismiss={onDismiss} variant={type}>
          <div className={classNames(classes.content)}>{children}</div>
        </Toast>
      )}
    </div>
  );
};
