import * as React from 'react';
import { Colors, createClasses } from '@kp/react-ui';
import { Header } from '../Header';
import { HEADER_HEIGHT } from '../../constants/UI';
import { Notifications } from '../Notifications';

const classes = createClasses({
  root: {},
  content: {
    padding: '1px 24px 24px 24px',
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    marginTop: `${HEADER_HEIGHT}px`,
    borderTop: '1px solid transparent',
    backgroundColor: Colors.Neutral.backgroundMedium,
  },
});

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.content}>{children}</div>
      <Notifications />
    </div>
  );
};
