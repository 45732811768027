import {
  ColorNeutral,
  Colors,
  Icon,
  Icons,
  Typography,
  createClasses,
  animations,
} from '@kp/react-ui';
import * as React from 'react';

interface WidgetGroupLocationProps {
  loading: boolean;
  building?: string;
  site?: string;
}

const classes = createClasses({
  root: {
    color: ColorNeutral.textWeak,
  },
  nameSkeleton: {
    height: '12px',
    width: '200px',
    margin: '4px 0',
    borderRadius: 2,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, .04) 50%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(${Colors.Neutral.backgroundHoverStrong} 100%, transparent 0);
    `,
    backgroundSize: `
      80% 12px,
      100% 100%;
    `,
    backgroundPosition: `
      -550% 0,
      0 0;
    `,
    animation: `${animations.slideRight} 1.5s infinite`,
  },
});

export const WidgetGroupLocation: React.FC<WidgetGroupLocationProps> = ({
  loading,
  building,
  site,
}) => {
  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.nameSkeleton} />
      ) : (
        <>
          <Typography variant="labelSmall">{site}</Typography>
          <Icon icon={Icons.IconChevronRight} size={16}></Icon>
          <Typography variant="labelSmall">{building}</Typography>
        </>
      )}
    </div>
  );
};
