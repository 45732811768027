import { Permissions } from '@kp/react-sdk';
import { AccountIcon } from '../features/application/AccountIcon';
import { BuildingInsightsIcon } from '../features/application/BuildingInsightsIcon';
import { DeveloperIcon } from '../features/application/DeveloperIcon';
import { AdministrationIcon } from '../features/application/AdministrationIcon';
import {
  ADMIN_APP_URI,
  ACCOUNT_APP_URI,
  DEVELOPER_APP_URI,
  BUILDING_INSIGHTS_APP_URI,
} from '../utils/env';

interface App {
  label: string;
  uri: string;
  permission?: Permissions;
  icon: any;
}

export const ADMINISTRATION: App = {
  label: 'Administration',
  uri: ADMIN_APP_URI,
  icon: AdministrationIcon,
};

export const BUILDING_INSIGHTS: App = {
  label: 'Building Insights',
  uri: BUILDING_INSIGHTS_APP_URI,
  permission: Permissions.WidgetsRead,
  icon: BuildingInsightsIcon,
};

export const ACCOUNT: App = {
  label: 'Account',
  uri: ACCOUNT_APP_URI,
  icon: AccountIcon,
};

export const DEVELOPER: App = {
  label: 'Developer',
  permission: Permissions.RegisteredApplicationsRead,
  uri: DEVELOPER_APP_URI,
  icon: DeveloperIcon,
};
