import React from 'react';
import { Colors, classNames, createClasses } from '@kp/react-ui';

interface CardProps extends React.ComponentPropsWithoutRef<'div'> {}

const classes = createClasses({
  root: {
    padding: '48px',
    border: `1px solid ${Colors.Neutral.borderStrong}`,
    boxShadow: `0px 0px 16px rgba(28, 46, 33, 0.12)`,
    borderRadius: '6px',
    background: Colors.Neutral.background,
  },
});

export const Card: React.FC<CardProps> = ({ className, ...props }) => (
  <div className={classNames(classes.root, className)} {...props} />
);
