import {
  Card,
  Colors,
  Dropdown,
  Icons,
  ListItem,
  createClasses,
} from '@kp/react-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupByToMap } from '../../api/common';
import { WidgetEntity } from '../../api/widgets/common';
import {
  getFavouriteWidgets,
  removeFromFavourite,
} from '../../api/widgets/favourites';
import { EmptyWidgetCard } from '../../components/EmptyWidgetCard';
import { useNotifications } from '../../contexts/notifications-context';
import { usePrepareRequest } from '../../hooks/useRequest';
import { WidgetListType } from './common';
import { WidgetGroup } from './WidgetGoup';
import { WidgetGroupLocationContainer } from './WidgetGroupLocationContainer';

const classes = createClasses({
  root: {
    marginTop: '56px',
    marginBottom: '4px',
  },

  body: {
    backgroundColor: Colors.Neutral.backgroundStrong,
    paddingTop: '20px',
    paddingBottom: '24px',
  },

  emptyWidget: {
    width: '392px',
    marginTop: '12px',
  },
});

export const WidgetsContainer: React.FC = () => {
  const [del] = usePrepareRequest(removeFromFavourite);
  const { t } = useTranslation();
  const { add, dismiss } = useNotifications();
  const [columns, setColumns] = useState(WidgetListType.THREE_COLUMNS);

  const [widgets, setWidgets] = useState<WidgetEntity[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFavouriteWidgets()
      .then((response) => {
        setLoading(false);
        if (response.error) {
          add({
            type: 'danger',
            id: `${response.error.message}`,
            content: `${response.error.message}`,
          });
          return [];
        }
        return setWidgets(response?.data?.data || []);
      })
      .catch(() => {
        setWidgets([]);
        setLoading(false);
      });
  }, [add]);

  const [widgetNotificationCount, setWidgetNotificationCount] = useState(0);

  const onRemoveFavourite = useCallback(
    async (widgetId: string) => {
      const deleteResponse = await del(widgetId);
      if (deleteResponse?.error) {
        add({
          type: 'danger',
          id: deleteResponse?.error.message,
          content: deleteResponse?.error.message,
        });
      } else {
        const arr = widgets.filter((item) => {
          return item.id !== widgetId;
        });
        setWidgets(arr);
        dismiss((widgetNotificationCount - 1).toString());
        setWidgetNotificationCount(widgetNotificationCount + 1);
        add({
          type: 'default',
          id: widgetNotificationCount.toString(),
          content: t('widgetContainer.widget.messages.widgetRemoved'),
          dismissTimeout: 3000,
        });
      }
    },
    [widgets, widgetNotificationCount, del, add, t, dismiss],
  );

  const handleChangeColumns = useCallback(
    async (value: string) => {
      switch (value) {
        case WidgetListType.THREE_COLUMNS:
        case WidgetListType.TWO_COLUMNS:
          setColumns(value);
      }
    },
    [setColumns],
  );

  const groupedWidgets = Array.from(
    groupByToMap(widgets, (a) => a.buildingId),
    ([name, value]) => ({
      name,
      value,
    }),
  );

  return (
    <>
      <Card
        className={classes.root}
        bodyClassName={classes.body}
        title={t('widgetContainer.title') as string}
        data-testid="widgets"
        actions={
          <Dropdown
            value={columns}
            onChange={handleChangeColumns}
            variant="tertiary"
            data-testid="change-columns-dropdown"
          >
            <ListItem
              icon={Icons.IconGrid3}
              value={WidgetListType.THREE_COLUMNS}
            />
            <ListItem
              icon={Icons.IconGrid2}
              value={WidgetListType.TWO_COLUMNS}
            />
          </Dropdown>
        }
      >
        {!loading &&
          groupedWidgets.length > 0 &&
          groupedWidgets.map((item) => (
            <WidgetGroup
              key={item.name}
              widgets={item.value}
              columns={columns}
              onRemoveFavourite={onRemoveFavourite}
            >
              <WidgetGroupLocationContainer buildingId={item.name} />
            </WidgetGroup>
          ))}
      </Card>
      {!loading && widgets.length === 0 && (
        <EmptyWidgetCard className={classes.emptyWidget} />
      )}
    </>
  );
};
