import { classNames, createClasses } from '@kp/react-ui';
import React from 'react';
import { WidgetEntity } from '../../api/widgets/common';
import { WidgetListType } from './common';
import { WidgetCardContainer } from './WidgetCardContainer';

const classes = createClasses({
  root: {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: 0,
    },
  },
  widgets: {
    gap: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginTop: '8px',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    overflow: 'auto',
  },
  [WidgetListType.TWO_COLUMNS]: {
    height: '437px',
    flexBasis: 'calc(50% - 8px)',
    '&:nth-child(2n)': {
      marginRight: '0',
    },
  },
  [WidgetListType.THREE_COLUMNS]: {
    height: '288px',
    flexBasis: 'calc(33.33% - 8px)',
    '&:nth-child(3n)': {
      marginRight: '0',
    },
  },
});

export interface WidgetGroupProps {
  widgets: WidgetEntity[];
  columns: WidgetListType;
  onRemoveFavourite: (widgetId: string) => Promise<void>;
  children?: React.ReactNode;
}

export const WidgetGroup: React.FC<WidgetGroupProps> = ({
  widgets,
  columns,
  onRemoveFavourite,
  children,
}) => {
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.widgets}>
        {widgets.map((widget) => (
          <div
            key={widget.id}
            className={classNames(classes.cardWrapper, classes[columns])}
          >
            <WidgetCardContainer
              id={widget.id}
              buildingId={widget.buildingId}
              name={widget.name}
              timeInterval={widget.timeInterval}
              dateFrom={widget.dateFrom}
              dateTo={widget.dateTo}
              aggregationInterval={widget.aggregationInterval}
              collapsed={widget.collapsed}
              createdAt={widget.createdAt}
              capabilities={widget.capabilities}
              onRemoveFavourite={onRemoveFavourite}
              type={widget.chartType}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
