import React, { useCallback } from 'react';
import { TimeUnit } from 'chart.js';
import { TimeScaleLineChart, TimeScaleBarChart } from '@kp/react-ui';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import { ChartType } from '../../api/widgets';
import { ChartDataSet, TimeRange } from '../../features/widgets/common';

interface ChartProps {
  data: ChartDataSet[];
  timeUnit: TimeUnit;
  collapsed: boolean;
  timeRange?: TimeRange;
  size?: 'small' | 'large';
  className?: string;
  type: ChartType;
  autoFit?: boolean;
}

const getChartComponent = (type: ChartType) => {
  switch (type) {
    case ChartType.line:
      return TimeScaleLineChart;
    case ChartType.bar:
      return TimeScaleBarChart;
    default:
      return TimeScaleLineChart;
  }
};

export const Chart: React.FC<ChartProps> = ({
  data,
  timeUnit,
  collapsed,
  timeRange,
  size = 'small',
  className,
  type,
  autoFit,
}) => {
  const { i18n, t } = useTranslation();
  const Component = getChartComponent(type);

  const chartData = {
    datasets: data.map(({ label, color, unitSymbol, records }) => ({
      label,
      unit: unitSymbol,
      data: records.map((record) => ({
        timestamp: record.x,
        value: record.y,
      })),
      color,
    })),
  };

  const handleTickFormat = useCallback(
    (value: number) =>
      value < 1000
        ? value.toString()
        : `${(value / 1000).toFixed(0)} ${t('chart.thousand')}`,
    [t],
  );

  return (
    <Component
      locale={i18n.language === 'de' ? de : en}
      time={{
        displayFormats: {
          minute: 'p',
          hour: 'p',
          day: i18n.language === 'de' ? 'd. MMM' : 'MMM, do',
        },
        unit: timeUnit,
      }}
      data={chartData}
      min={timeRange?.min.toISOString()}
      max={timeRange?.max.toISOString()}
      size={size}
      className={className}
      collapsed={collapsed}
      yTickFormat={size === 'small' ? handleTickFormat : undefined}
      autoFit={autoFit}
    />
  );
};
