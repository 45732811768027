import React from 'react';

interface BuildingInsightsIconProps {
  className?: string;
}

export const BuildingInsightsIcon: React.FC<BuildingInsightsIconProps> = ({
  className,
}) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="64" height="64" rx="6" fill="#236EF1" />
    <path
      d="M32 22V18C32 15.8 30.2 14 28 14H16C13.8 14 12 15.8 12 18V46C12 48.2 13.8 50 16 50H48C50.2 50 52 48.2 52 46V26C52 23.8 50.2 22 48 22H32ZM20 46H16V42H20V46ZM20 38H16V34H20V38ZM20 30H16V26H20V30ZM20 22H16V18H20V22ZM28 46H24V42H28V46ZM28 38H24V34H28V38ZM28 30H24V26H28V30ZM28 22H24V18H28V22ZM48 46H32V42H36V38H32V34H36V30H32V26H48C48 28.061 48 46 48 46ZM44 30H40V34H44V30ZM44 38H40V42H44V38Z"
      fill="white"
    />
  </svg>
);
