interface RequestErrorData {
  message?: string;
  code?: string;
}

export class RequestError extends Error {
  responseCode?: string;

  responseMessage?: string;

  constructor(message: string, data?: RequestErrorData) {
    super(message);
    this.responseCode = data?.code;
    this.responseMessage = data?.message;
  }
}
